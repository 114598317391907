import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";

export default function DevOps() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" , height: 150}}
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h4>Drupal</h4>              
            </div>
          </div>
        </div> */}
      </div>
      {/* End Breadcrumb */}

      <div className="services-details-area default-padding pb-4">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-12 services-single-content">
                <h2>Why DevOps is important and DevOps model</h2>
                <p>
                  DevOps is one of the latest trend in the world of software
                  development. In a company, The team of developers works and
                  communicationg with the DevOps Engineers to make sure that the
                  software runs effectively and efficiently. And also, Most of
                  the teams connected with software development are going for
                  the DevOps.
                </p>

                <p>
                  What is the concept of DevOps? It is related to software
                  development, IT services and IT operations between the
                  information operation personnel and that of software
                  developers, DevOps helps in building communications,
                  integration and collaboration.
                </p>
              </div>
              <div className="col-12 services-sidebar">
                <img
                  src="assets/img/list/cloud/6.png"
                  className="mt-5"
                  alt="Thumb"
                />
              </div>
            </div>

            <div className="row default-padding pb-0">
              <div className="col-12 services-single-content pl-5">
                <h2>
                  {" "}
                  There is an improvement in collaboration and communication{" "}
                </h2>
                <p>
                  There are few improvements in the culture of software
                  development by using DevOps. The development and DevOps team
                  when combined they are produced and they become more focused
                  on performing rather than having separate tasks and sprint
                  plans.
                </p>
                <p>
                  If both the teams are combined they will be able to trust each
                  other. DevOps team will be able to have more focus on the
                  production so that they can launch the product in the Live
                  more quickly.
                </p>
                <p>
                  With the help of DevOps engineers, the team at the operations
                  level don't have to wait for finding an bugs and then fix it.
                </p>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 services-sidebar">
                <img
                  src="assets/img/list/cloud/4.png"
                  className="mt-5"
                  alt="Thumb"
                />
              </div>
              <div className="col-12 services-sidebar default-padding d-xs-none">
                <img
                  src="assets/img/list/cloud/5.png"
                  className="mt-5"
                  alt="Thumb"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    top: "-100%",
                    zIndex: 99,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Star About Area
    ============================================= */}
      <div className="about-area bg-dark overflow-hidden text-light relative">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="about-items">
            <div className="row">
              <div
                className="col-lg-6 thumb order-lg-last"
                style={{ backgroundImage: "url(assets/img/list/cloud/3.png)" }}
              >
                <div className="successr-ate">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="content">
                    <h2>100%</h2>
                    <span>Success Rate</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>Who we are</h4>
                <h2>
                  We Combine Technology <br /> with Business Ideas
                </h2>
                <p>
                  Opensoft Group is a leading IT solutions company that excels
                  in delivering Web development and android applications.
                </p>
                <p>
                  With an experience of 2+ years, we aim to deliver excellent
                  solutions to our genuine clients. We understand that future
                  world is rapidly shifting towards data-driven and AI, Machine
                  learning technologies.
                </p>
                <ul>
                  <li>
                    <h5>Innovate with perfection</h5>
                    <p>
                      Yes, You have landed on the right page. We are here to
                      help our client in every possible way.
                    </p>
                  </li>
                  <li>
                    <h5>Information Security Management</h5>
                    <p>
                      We prefer quality over quantity and that is what makes
                      Opensoft Group one of the bests in the market. We are here
                      to help our client in every possible way.
                    </p>
                  </li>
                </ul>
                <Link to="/contact-us" className="btn btn-light effect btn-md">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
    </div>
  );
}
