import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ClientList from "../GenericComponents/ClientList";
import Testimonials from "../GenericComponents/Testimonials";
import ComingSoon from "./ComingSoon";

export default function ProductDevelopment() {
  const dataList = [
    {
      title: "Idea, Screening & Project Evalution",
      description:
        "It helps you to vet and evaluate potential ideas using set criteria",
    },
    {
      title: "Design & Development",
      description:
        "This step plays major role in the product development process",
    },
    {
      title: "Testing Application",
      description:
        "Once we're done with development it goes to testing category to review.",
    },
    {
      title: "Market Launch",
      description: "Finally, It's on Live",
    },
  ];

  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
      >
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Product Development</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Product Dev.</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Expertise Area */}
      <div className="expertise-area default-padding pb-0 pt-5">
        <h2 className="text-center mb-5">
          Digital Product Deelopment Company for Software & Web
        </h2>

        <div className="container">
          {/* Expertise Content */}
          <div
            className="expertise-content text-light"
            style={{ backgroundImage: "url(assets/img/list/other/1.png)" }}
          >
            <div className="row">
              {dataList.map((data) => {
                return (
                  <div className="col-lg-3 single-item">
                    <div className="item">
                      <div className="content">
                        <h4>{data.title}</h4>
                        <p>{data.description}</p>
                      </div>
                      <Link to="/services" className="btn btn-sm">
                        Know More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Fixed Shape */}
            <div
              className="fixed-shape"
              style={{ backgroundImage: "url(assets/img/shape/13.png)" }}
            />
            {/* Fixed Shape */}
          </div>
          {/* Expertise Content */}
        </div>
      </div>
      {/* End Expertise Area */}

      {/* Start Terms Policy
       */}
      <div className="terms-policy-area default-padding">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <section>
                <h3>What is the Product Development Process?</h3>
                <p>
                  The product development process is a six stage plan that takes
                  a product from initial to final stage of project launch. This
                  includes identifying a market need, researching the
                  competation, and developing a minimum viable product (MVP).
                </p>

                <h3 className="mt-5">
                  How to Create a Product Development Strategy
                </h3>
                <ul>
                  <li>
                    <span>01</span>
                    <p>Product management</p>
                  </li>
                  <li>
                    <span>02</span>
                    <p>Design (UX/UI) & Development</p>
                  </li>
                  <li>
                    <span>03</span>
                    <p>Testing and QA</p>
                  </li>
                  <li>
                    <span>04</span>
                    <p>Shipping or Distribution</p>
                  </li>
                </ul>

                <h3 className="mt-5">Agile Product Development Strategy</h3>
                <p>
                  <p>
                    The basic concepts of a product development strategy should
                    remain constant in any type of organization. That means
                    creating a step-by-step approach to development, keeping
                    everyone aligned around the overall strategy. But agile
                    companies approach development differently, and this will
                    affect some of the details of how they plan and execute
                    their product development strategy.
                  </p>
                  <p>
                    The main difference will be in how quickly the team iterates
                    its product based on market feedback. In a traditional
                    organization, the product team will spend more time and
                    build a complete product before putting it into users’ hands
                    and analyzing their feedback.
                  </p>
                  <p>
                    In an agile development organization, by contrast, the team
                    will build the minimum functional solution they can and
                    release it to users as quickly as they can. It is where we
                    get the concept of a minimum viable product.
                  </p>

                  <p>
                    The agile approach can make for a better product development
                    strategy. It allows the product team to spend more time
                    gathering and analyzing real-world feedback—because it’s
                    putting the product into users’ hands closer to the
                    beginning of the process. This process means every
                    subsequent stage of development can benefit from analyzing
                    actual product usage or from hearing users’ thoughts and
                    feelings about the product.
                  </p>
                </p>

                <h3 className="mt-5"> Here we go!</h3>

                <img src="assets/img/list/other/2.png" className="mb-5" />
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}


      <Testimonials />


      {/* Star Partner Area
    ============================================= */}
      <div className="partner-area bg-gray shadow-less overflow-hidden text-light">
        <div className="container">
          <div className="item-box">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h2>
                  We're Trusted by <span>2500+</span> <br /> Professional
                  Customer
                </h2>
                <p>
                  Seeing rather her you not esteem men settle genius excuse.
                  Deal say over you age from. Comparison new ham melancholy son
                  themselves.
                </p>
              </div>
              <div className="col-lg-6 clients">
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}
    </div>
  );
}
