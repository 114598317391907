import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Flutter() {
  const collapseList = [
    {
      title: "Same UI and Business Logic in All Platforms.",
      description: "",
    },
    {
      title: "Reduced Code Development Time",
      description: "",
    },
    {
      title: "Increased Time-to-Market Speed.",
      description: "",
    },
    {
      title: "Similar to Native App Performance.",
      description: "",
    },
    {
      title: "Custom, Animated UI of Any Complexity Available. ",
      description: "",
    },
    {
      title: "Own Rendering Engine.",
      description: "",
    },
    {
      title: "Simple Platform-Specific Logic Implementation. ",
      description: "",
    },
    {
      title: "The Potential Ability to Go Beyond Mobile."
    }
  ];

  const workprocessAreaList = [
    {
      sno: "01",
      title: "Cross-Platform",
      description:
        "Apps work in both iOS and Android and Best for small development teams with limited resources",
    },
    {
      sno: "02",
      title: "Developers",
      description: "Developers having a strong web development background",
    },
    {
      sno: "03",
      title: "Long-term support",
      description: "Not recommended as the framework can get shut down",
    },
  ];

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Flutter Usage'
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'no. of companies'
      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}'
        }
      }
    },

    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
    },

    series: [
      {
        name: "Comapnies",
        colorByPoint: true,
        data: [
          {
            name: "Startup",
            y: 1000,
            drilldown: "Startup"
          },
          {
            name: "Mid Cap Companies",
             y: 1500,
            drilldown: "Mid Cap Companies"
          },
          {
            name: "MNC",
            y: 500,
            drilldown: "MNC"
          }
        ]
      }
    ],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right'
        }
      },
      series: [
        {
          name: "Startup",
          id: "Startup",
          data: []
        },
        {
          name: "Mid Cap Companies",
          id: "Mid Cap Companies",
          data: []
        },
        {
          name: "MNC",
          id: "MNC",
          data: []
        },
      ]
    }
  };

  return (
    <div>
      {/* Start Breadcrumb
       */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: "url(assets/img/banner/no-breadcrumb-banner.jpg)",
        }}
      ></div>
      {/* End Breadcrumb */}

      {/* Start Terms Policy
       */}
      <div className="terms-policy-area mt-5">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h3>Flutter App Development?</h3>
              <p>
                Flutter has come up with a completely new vision to build mobile and iPad applications. Do you know what? Not only does it provide a reliable framework for application development but also enables the developers with a portable runtime features to host the undeveloped applications. This add-on provides features that can be executed in place of widgets that act as wrappers on native controls.
              </p>
              <p>Opensoft always help you. The Flutter approach provides greater flexibility for developers to build a fully customizable, cross-platform application in the same way as the one developed by the web wrapper approach. The only difference is the much smoother performance of the application.</p>

              <img src="assets/img/list/mobile-app/6.png" className="mb-5" />
              {/* <img src="assets/img/list/mobile-app/3.png" className="mb-5 overlap-img" /> */}

              <h3>Companies Migrated to Flutter</h3>
         

              <HighchartsReact highcharts={Highcharts} options={options} />

              <h3>Who's using Flutter?</h3>
              <ul>
                <li>
                  <span>01</span>
                  <p>
                    If you're curious to see what can be accomplished with Flutter, check out these applications like Google Ads,
                    KlasterMe, Reflectly, Hamilton, Lunching, Pairing and many
                    more...
                  </p>
                </li>
                <li>
                  <span>02</span>
                  <p>
                    FlutterFlow makes it easy for designers, developers, & entrepreneurs to build android and iOS applications.
                  </p>
                </li>
              </ul>

              
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}


      {/* Star Faq
       */}
      <div className="faq-area text-light overflow-hidden">
        <div className="container">
          <div className="faq-items">
            <div className="icon">
              <i className="flaticon-conversation" />
            </div>
            {/* Fixed Thumb */}
            <div className="fixed-thumb">
              <img src="assets/img/illustration/1.png" alt="Thumb" />
            </div>
            {/* End Fixed Thumb */}
            <div className="row align-center">
              <div className="col-lg-10 offset-lg-2">
                <div className="faq-content">
                  <h2 className="area-title">What is the benefits of flutter?</h2>
                  <div className="accordion" id="accordionExample">
                    {collapseList.map((data) => {
                      return (
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h4
                              className="mb-0"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {data.title}
                            </h4>
                          </div>
                          {/* <div
                                  id="collapseOne"
                                  className="collapse show"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    <p>
                                    {data.description}
                                    </p>
                                  </div>
                                </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq */}
    </div>
  );
}
