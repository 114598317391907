import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import ComingSoon from "./ComingSoon";

const Contactus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uxs34gg",
        "template_x81lzzk",
        form.current,
        "user_FIgygPWj3vQuFBn77mIao"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.error?.message
              ? error?.response?.data?.error?.message
              : "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
    e.target.reset();
  };

  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/banner/2.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Contact Us</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Contact Area 
    ============================================= */}
      <div className="contact-us-area default-padding">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-5 info">
              <div className="content">
                <h2>Let's talk?</h2>
                <p>
                  It's all about the humans behind a brand and those
                  experiencing it, we're right there. In the middle performance
                  quick.
                </p>
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <h5>Address</h5>
                      <p>
                        405 State Highway 121 <br />
                        Suite A250 <br />
                        Lewisville TX 75067.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="content">
                      <h5>Contact</h5>
                      <p>+980-222-1233</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 contact-form-box">
              <div className="form-box">
                <form id="checkout-form" ref={form} onSubmit={sendEmail}>
                  <div className="row d-flex flex-column">
                    <div className="mb-3">
                      <h2 className="sidebar-title">Full your details</h2>
                    </div>
                    <div className="mb-0">
                      <div className="form-group">
                        <label>Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter subject"
                          name="subject"
                        />
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your name"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className=" mb-0">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="form-group">
                        <label>Mobile number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter your mobile number"
                          name="mobile"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="pLace-order mt-3">
                        <button
                          className="view-all-accent-btn"
                          type="submit"
                          value="Submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Contact Area */}
      {/* Star Google Maps
    ============================================= */}
      <div className="maps-area">
        <div className="google-maps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.4578284008153!2d-96.98325528426726!3d32.991703180907926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2ec146c00001%3A0x2e44d15e28713eb5!2s405%20TX-121%20Building%20A%20Suite%20A250%2C%20Lewisville%2C%20TX%2075057%2C%20USA!5e0!3m2!1sen!2sin!4v1676474291873!5m2!1sen!2sin"></iframe>
        </div>
      </div>
      {/* End Google Maps */}
    </div>
  );
};

export default Contactus;
