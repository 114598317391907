import React, { useEffect, useState, FunctionComponent } from "react";
import Router from "../../routes";

import { Route, Link, Switch, Redirect } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const [scrolled, setScrolled] = useState([] as any);

  return (
    <>
      {/* Start Footer 
    ============================================= */}
      <footer className="bg-dark text-light">
        <div className="container">
          <div className="f-items default-padding">
            <div className="row">
              <div className="col-lg-4 col-md-6 item">
                <div className="f-item about">
                  <img src="assets/img/logo-light.png" alt="Logo" />
                  <p>
                  Opensoft Group is a leading IT solutions company that excels in delivering Web development and android applications.
                  </p>
                  <form action="#">
                    <input
                      type="email"
                      placeholder="Your Email"
                      className="form-control"
                      name="email"
                    />
                    <button type="submit">
                      {" "}
                      <i className="arrow_right" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 item">
                <div className="f-item link">
                  <h4 className="widget-title">Quick LInk</h4>
                  <ul>
                    <li>
                      <Link to="/">
                        <i className="fas fa-angle-right" /> Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us">
                        <i className="fas fa-angle-right" /> About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                        <i className="fas fa-angle-right" /> Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers">
                        <i className="fas fa-angle-right" /> Careers
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        <i className="fas fa-angle-right" /> Reach Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item link">
                  <h4 className="widget-title">Community</h4>
                  <ul>
                    <li>
                      <Link to="hire-skilled-engineers">
                        <i className="fas fa-angle-right" /> Hire Skilled
                        Engineers
                      </Link>
                    </li>
                    <li>
                      <Link to="product-development">
                        <i className="fas fa-angle-right" /> Product Development
                      </Link>
                    </li>
                    <li>
                      <Link to="it-architecture">
                        <i className="fas fa-angle-right" /> IT Architecture
                      </Link>
                    </li>
                    <li>
                      <Link to="/angular"><i className="fas fa-angle-right" /> Web framworks</Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        <i className="fas fa-angle-right" /> Blog
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item contact-widget">
                  <h4 className="widget-title">Contact Info</h4>
                  <div className="address">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="fas fa-home" />
                        </div>
                        <div className="content">
                          <strong>Address:</strong>
                          405 State Highway 121 Suite A250 Lewisville TX 75067
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-envelope" />
                        </div>
                        <div className="content">
                          <strong>Email:</strong>
                          <a href="mailto:hr@opensoftgroup.com">
                            hr@opensoftgroup.com
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-phone" />
                        </div>
                        <div className="content">
                          <strong>Phone:</strong>
                          <a href="tel:2151234567">+980-222-1233</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    © Copyright 2020. All Rights Reserved.{" "}
                    <a href="#">Developed by NEWDEVEX</a>
                  </p>
                </div>               
              </div>
            </div>
          </div>
        </div>
        {/* End Footer Bottom */}
      </footer>
      {/* End Footer */}
    </>
  );
};

export default Footer;
