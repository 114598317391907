import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ReactNative() {
  const collapseList = [
    {
      title: "Cross-Platform",
      description:
        "You'll learn how to use functions, methods, and packages to efficiently leverage the code that brilliant Python developers have written. The goal is to reduce the amount of code you need to solve challenging problems",
    },
    {
      title: "JavaScript",
      description: "",
    },
    {
      title: "Performance",
      description: "",
    },
    {
      title: "Large Developers’ Community",
      description: "",
    },
    {
      title: "It Keeps Getting Better",
      description: "",
    },
    {
      title: "Used by Many Companies",
      description: "",
    },
    {
      title: "Excellent Career Opportunities",
      description: "",
    },
  ];

  const workprocessAreaList = [
    {
      sno: "01",
      title: "Cross-Platform",
      description:
        "Apps work in both iOS and Android and Best for small development teams with limited resources",
    },
    {
      sno: "02",
      title: "Developers",
      description: "Developers having a strong web development background",
    },
    {
      sno: "03",
      title: "Long-term support",
      description: "Not recommended as the framework can get shut down",
    },
  ];

  const options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "React-Native Graph",
    },
    subtitle: {
      text: "Thousands of applications are using React Native",
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    xAxis: {
      labels: {
        skew3d: true,
        style: {
          fontSize: "16px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Companies usage",
      },
    },
    series: [
      {
        name: "Growth",
        data: [10, 100, 150, 170, 500],
      },
    ],
  };

  return (
    <div>
      {/* Start Breadcrumb
       */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: "url(assets/img/banner/no-breadcrumb-banner.jpg)",
        }}
      ></div>
      {/* End Breadcrumb */}

      {/* Start Terms Policy
       */}
      <div className="terms-policy-area mt-5">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h3>Migrate to React Native?</h3>
              <p>
                Opensoft group team has extensive experience in migrating any
                kind of applications and platforms to React Native. And also, we
                offer strategic assistance in enabling smooth, hassle-free
                migration of your legacy suites to the latest React Native
                release while ensuring access to higher-quality APIs, UI
                elements, developer tools, and many more.
              </p>

              <h3>Companies Migrated!</h3>
              {/* <div className="lineChart col-8">               
                <canvas id="lineChart" height={200} />
              </div> */}

              <HighchartsReact highcharts={Highcharts} options={options} />

              <h3>Who's using React Native?</h3>
              <ul>
                <li>
                  <span>01</span>
                  <p>
                    If you're curious to see what can be accomplished with React
                    Native, check out these applications like Facebook,
                    Instagram, Oculus, Coinbase, Tableau, Bloomberg and many
                    more...
                  </p>
                </li>
                <li>
                  <span>02</span>
                  <p>
                    Thousands of applications are using React Native, from
                    established Fortune 500 companies to hot new startups.
                  </p>
                </li>
              </ul>

              <img src="assets/img/list/mobile-app/1.png" className="mb-5" />
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}


      {/* Star Faq
       */}
      <div className="faq-area text-light overflow-hidden">
        <div className="container">
          <div className="faq-items">
            <div className="icon">
              <i className="flaticon-conversation" />
            </div>
            {/* Fixed Thumb */}
            <div className="fixed-thumb">
              <img src="assets/img/illustration/1.png" alt="Thumb" />
            </div>
            {/* End Fixed Thumb */}
            <div className="row align-center">
              <div className="col-lg-10 offset-lg-2">
                <div className="faq-content">
                  <h2 className="area-title">Why Use React Native?</h2>
                  <div className="accordion" id="accordionExample">
                    {collapseList.map((data) => {
                      return (
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h4
                              className="mb-0"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {data.title}
                            </h4>
                          </div>
                          {/* <div
                                  id="collapseOne"
                                  className="collapse show"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    <p>
                                    {data.description}
                                    </p>
                                  </div>
                                </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq */}
    </div>
  );
}
