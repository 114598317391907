import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import axios from "axios";

//components
import { API_URL } from "../../constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FeaturedCourses from "../GenericComponents/FeaturedCourses";
import Clients from "../GenericComponents/Clients";
import ClientList from "../GenericComponents/ClientList";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ServicesList from "../GenericComponents/ServicesList";
import WorksGallery from "./WorksGallery";
import GenericBlog from "../GenericComponents/GenericBlog";

class Home extends React.Component {

  constructor() {
    super();
    this.state = {
      campaigns: [],
      isLoading: false,
    };
  }

  render() {
    const silde_1 = require("../../assets/images/weoffer/python.png");

    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Opensoft Group Company Growth'
      },
      subtitle: {
        text: 'Business Chart'
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'value'
        }

      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },

      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
      },

      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: [
            {
              name: "Employees",
              y: 1500,
              drilldown: "Employees"
            },
            {
              name: "Selected Candidates",
              y: 1800,
              drilldown: "Selected Candidates"
            },
            {
              name: "Interviewed Candidates",
              y: 4500,
              drilldown: "Interviewed Candidates"
            }
          ]
        }
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: 'right'
          }
        },
        series: [
          {
            name: "Employees",
            id: "Employees",
            data: []
          },
          {
            name: "Firefox",
            id: "Firefox",
            data: []
          },
          {
            name: "Interviewed Candidates",
            id: "Interviewed Candidates",
            data: []
          },
        ]
      }
    };

    return (
      <div>

        {/* Start Banner */}
        <div className="banner-area text-center text-big top-pad-50">
          <div id="bootcarousel" className="carousel text-light slide animate_text" data-ride="carousel">
            {/* Wrapper for slides */}
            <div className="carousel-inner carousel-zoom">
              <div className="carousel-item active">
                <div className="slider-thumb bg-fixed" style={{ backgroundImage: 'url(assets/img/banner/5.jpg)' }} />
                <div className="box-table">
                  <div className="box-cell shadow dark-hard">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                          <div className="content">
                            <h2 data-animation="animated zoomInLeft">Diverse <span>Inclusive Workplace</span></h2>
                            <p className="animated slideInRight">
                              Opensoft Group is always help you on unlock better team collaboration, communication, improved innovation and increased financial revenue and performance by great place to work.
                            </p>
                            <Link to="/contact-us" data-animation="animated zoomInUp" className="btn btn-gradient effect btn-md">Discover More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slider-thumb bg-fixed" style={{ backgroundImage: 'url(assets/img/banner/1.jpg)' }} />
                <div className="box-table">
                  <div className="box-cell shadow dark-hard">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                          <div className="content">
                            <h2 data-animation="animated slideInDown">Recruitment <span>Pipeline</span></h2>
                            <p className="animated slideInRight">
                              We hire top skilled engineers. Earning great place to work proves that you have created an excellent employee experience and elevates employee status as employer choice.
                            </p>
                            <Link to="/contact-us" data-animation="animated zoomInUp" className="btn btn-gradient effect btn-md">Discover More</Link>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Wrapper for slides */}
            {/* Left and right controls */}
            <a className="left carousel-control light" href="#bootcarousel" data-slide="prev">
              <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control light" href="#bootcarousel" data-slide="next">
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* End Banner */}
        {/* Start About Area*/}
        <div className="about-content-area default-padding">
          <div className="container">
            <div className="content-box">
              <div className="row">
                <div className="col-lg-6 thumb">
                  <div className="thumb-box">
                    <img src="assets/img/about/2.jpg" alt="Thumb" />
                    <img src="assets/img/about/3.jpg" alt="Thumb" />
                    <div className="shape" style={{ backgroundImage: 'url(assets/img/shape/1.png)' }} />
                  </div>
                </div>
                <div className="col-lg-6 info">
                  <h2>We Combine Technology <br /> with Business Ideas</h2>
                  <ul>
                    <li>
                      <div className="icon">
                        <i className="ti-bookmark-alt" />
                      </div>
                      <div className="info">
                        <h4>Management View</h4>
                        <p>
                          Company Management has a clear view of where the organization is going and how to get there.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="ti-id-badge" />
                      </div>
                      <div className="info">
                        <h4>Our Experience Employees</h4>
                        <p>
                          Opensoft employees avoid politicking and backstabbing as ways to get things done and also, celebrate special events around here.
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div className="call">
                    <div className="icon">
                      <i className="flaticon-telephone" />
                    </div>
                    <div className="intro">
                      <h5>+980-222-1233</h5>
                      <span>Call to ask any question</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 center-info">
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About */}
        {/* Star Partner Area*/}
        <div className="partner-area shape-less overflow-hidden text-light">
          <div className="container">
            <div className="item-box">
              <div className="row align-center">
                <div className="col-lg-6 info">
                  <h2>
                    We're Trusted by <span>200+</span> <br /> Professional
                    Clients
                  </h2>
                  <p>
                    Our company mission is to build a better world by helping other IT organizations become a great place to work for all to fill their dreams.
                  </p>
                </div>
                <div className="col-lg-6 clients">
                  <ClientList />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Partner Area */}


        <ServicesList sectionheading="OUR SERVICES" sectiondes="What We Bring To You" />


        {/* Start Business Growth*/}
        <div className="business-groth-area default-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 info">
                <h4>Working with us?</h4>
                <h2>
                  It All Comes Down <br />
                  To Trust
                </h2>
                <p>
                  We provides an unparalleled opportunity to partner, clients and who are work for us with some of the biggest brands, most creative minds and inspiring leaders who sit at the forefront of creating and maintaining innovative and successful organizations. 
                </p>

                <p>
                We always welcome talented skilled engineers, high energetic, decent team members to join our team and help us to create a better society by helping other companies transform their workplaces.
                </p>
            
                <Link to="/contact-us" className="btn btn-gradient effect btn-md">
                  Reach us
                </Link>
              </div>
              <div className="col-lg-6 homepage">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            </div>
          </div>
        </div>
        {/* End Business Growth */}
      

        <GenericBlog heading="Few Blogs from other source" des="Latest Articles"/>

      </div>
    );
  }
}

export default Home;
