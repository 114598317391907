import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";

export default function SquareSpace() {
  return (
    <div>
    {/* Start Breadcrumb 
  ============================================= */}
    <div
      className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
      // style={{ backgroundImage: "url(assets/img/2440x1578.png)" , height: 150}}
    >
      {/* <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h4>Drupal</h4>              
          </div>
        </div>
      </div> */}
    </div>
    {/* End Breadcrumb */}

    <div className="services-details-area default-padding pb-4">
      <div className="container">
        <div className="services-details-items">
          <div className="row">
            <div className="col-12 col-lg-8 col-md-8 col-sm-8 services-single-content">
              <h2>What framework does Squarespace use?</h2>
              <p>
                Squarespace is an user friendly website builder that it's pretty much all-inclusive,
                kind of eliminating coding and makes everything drag-and-drop just click on menus it's really 
                easy very graphic user interface style.
                
              </p>

              <p>
               Squarespace is an great platform to start a blogor an e-commerce courses,personal website of a resume or a photographer ect,.
              </p>
              <p>opensoft makes it easy for you to create a modern professional website using Squarespace </p>
                <p>Opensoft having a single code bitbucket repository, automated build
                process, and testing environment that reflects production as
                closely as possible. We often commit changes and perform
                automated testing.</p>
            </div>
            <div className="col-12 col-lg-4 col-md-4 col-sm-4 services-sidebar">
              <img src="assets/img/list/cms/5.png" alt="Thumb" />
            </div>
          </div>

          <div className="row default-padding pb-0 pt-5">
          <div className="col-lg-12 services-sidebar">
              <img src="assets/img/list/cms/7.gif" alt="Thumb" />
            </div>
            <div className="col-lg-8 services-single-content pl-5">
              <h2>Cutomize your website!</h2>
              <p>
              Since 2020, Opensoft built no. of websites for companies representing a broad range of information technology companies, manufacturing, finance, charity, ecommerce web applications, publishing and ecommerce. Drupal is a great choice for all the IT companies either MNC, midcap and small cap companies. 
              </p>
              <p>
              So we're ready to cooperate with any type of business. We’re always excited to take up new challenges. Look at the projects we've already completed.
              </p>

            </div>
            
          </div>

        </div>
      </div>
    </div>

    {/* Star About Area
  ============================================= */}
    <div className="about-area bg-dark overflow-hidden text-light relative">
      {/* Fixed Shape */}
      <div
        className="fixed-shape"
        style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
      />
      {/* End Fixed Shape */}
      <div className="container">
        <div className="about-items">
          <div className="row">
            <div
              className="col-lg-6 thumb order-lg-last"
              style={{ backgroundImage: "url(assets/img/list/cms/8.png)" }}
            >
              <div className="successr-ate">
                <div className="icon">
                  <i className="flaticon-target" />
                </div>
                <div className="content">
                  <h2>45%</h2>
                  <span>Success Rate</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 info">
              <h4>Who we are</h4>
              <h2>
                We Combine Technology <br /> with Business Ideas
              </h2>
              <p>
              Opensoft Group is a leading IT solutions company that excels in delivering Web development and android applications.

              </p>
              <p>With an experience of 2+ years, we aim to deliver excellent solutions to our genuine clients. We understand that future world is rapidly shifting towards data-driven and AI, Machine learning technologies.
</p>
              <ul>
                <li>
                  <h5>Innovate with perfection
</h5>
                  <p>
                  Yes, You have landed on the right page. We are here to help our client in every possible way.
                  </p>
                </li>
                <li>
                  <h5>Information Security Management
</h5>
                  <p>
                  We prefer quality over quantity and that is what makes Opensoft Group one of the bests in the market. We are here to help our client in every possible way.
                  </p>
                </li>
              </ul>
              <Link to="/contact-us" className="btn btn-light effect btn-md">
                Discover More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End About Area */}
  </div>
  );
}
