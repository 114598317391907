import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";

export default function WebFrameList() {
  return (
    <div className="position-sticky">
      {/* Single Widget */}
      <div className="single-widget services-list">
        <h4 className="widget-title">All Web Frameworks</h4>
        <div className="content">
          <ul>
             <li className="" >
              <NavLink to="/angular" activeClassName="current-item">Angular</NavLink>
            </li>  
          <li className="">
              <NavLink to="/full-stack-development" activeClassName="current-item">Full-Stack</NavLink>
            </li>  
            
            <li className="">
              <NavLink to="/react" activeClassName="current-item">ReactJS</NavLink>
            </li>   
            <li className="">
              <NavLink to="/ruby" activeClassName="current-item">Ruby on rails</NavLink>
            </li> 
            <li className="">
              <NavLink to="/vue" activeClassName="current-item">VueJS</NavLink>
            </li>                     
          </ul>
        </div>
      </div>
      {/* End Single Widget */}
      <div
        className="single-widget quick-contact text-light"
        style={{ backgroundImage: "url(assets/img/about/11.png)" }}
      >
        <div className="content">
          <i className="fas fa-phone" />
          <h4>Need any help?</h4>
          <p>
            We are here to help our customer any time. You can call on 24/7 To
            Answer Your Question.
          </p>
          <h2>+980-222-1233</h2>
        </div>
      </div>
      {/* Single Widget */}
      {/* <div className="single-widget brochure">
        <h4 className="widget-title">Brochure</h4>
        <ul>
          <li>
            <a href="#">
              <i className="fas fa-file-pdf" /> Download Brochure{" "}
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-file-pdf" /> Company Details{" "}
            </a>
          </li>
        </ul>
      </div> */}
      {/* End Single Widget */}
    </div>
  );
}
