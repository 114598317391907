import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ClientList from "../GenericComponents/ClientList";
import Testimonials from "../GenericComponents/Testimonials";
import ComingSoon from "./ComingSoon";

export default function ITArchitecture() {
  const dataList = [
    {
      title: "Desired reporting and analysis",
      description:
        "",
    },
    {
      title: "Current reporting and data shortcomings and workarounds",
      description:
        "",
    },
    {
      title: "Work Priorities",
      description:
        " ",
    },
    {
      title: "Analytical skills",
      description: "",
    },
  ];

  return (
    <div>
      {/* Start Breadcrumb */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
      >
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Product Development</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Product Dev.</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Expertise Area */}
      <div className="expertise-area default-padding pb-0 pt-5">
        <h2 className="text-center mb-5">Technology-Driven</h2>

        <div className="container">
          {/* Expertise Content */}
          <div
            className="expertise-content text-light"
            style={{ backgroundImage: "url(assets/img/list/other/7.png)" }}
          >
            <div className="row">
              {dataList.map((data) => {
                return (
                  <div className="col-lg-3 single-item">
                    <div className="item">
                      <div className="content">
                        <h4>{data.title}</h4>
                        <p>{data.description}</p>
                      </div>
                      <Link to="/services" className="btn btn-sm">
                        Know More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Fixed Shape */}
            <div
              className="fixed-shape"
              style={{ backgroundImage: "url(assets/img/shape/13.png)" }}
            />
            {/* Fixed Shape */}
          </div>
          {/* Expertise Content */}
        </div>
      </div>
      {/* End Expertise Area */}

      {/* Start Terms Policy */}
      <div className="terms-policy-area default-padding pb-0">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="test-1">
                <section>
                  <h3>What is IT Architecture?</h3>
                  <p>
                    IT Architecture is the method to develop Information Technology guidelines, specifications and models.
                  </p>
                  <p>
                    And also, distinguished by enterprise Achitecture which explains and defines the architecture of whole compnay,
                    Solution Architecture describes the functionalities that are needed to a particular company which is typically appiles to a single project, high-level business, IT system,
                    Technology Architecture explains the detailed information of the various technology componentsthat are needed to a business object.
                  </p>
                  <p>
                    Opensoft Group provides a universal,coherent and consistent way to display and discusses about delivery and design solution's IT capabilities.
                  </p>
                </section>

                <section>
                  <h3 className="mt-5">Technology-Driven</h3>
                  <ul>
                    <li>
                      <span>01</span>
                      <p>Obtaining Executive and Support.</p>
                    </li>
                    <li>
                      <span>02</span>
                      <p>Establishing control and management structure.</p>
                    </li>
                    <li>
                      <span>03</span>
                      <p>Defining an Architecture process and approach.</p>
                    </li>
                    <li>
                      <span>04</span>
                      <p>Developing target Enterprise Architecture.</p>
                    </li>
                    <li>
                      <span>05</span>
                      <p>Developing the sequence planning. </p>
                    </li>
                    <li>
                      <span>06</span>
                      <p>Using the Enterprise Achitecture</p>
                    </li>
                    <li>
                      <span>07</span>
                      <p>Maintain the Enterprise Architecture.</p>
                    </li>
                  </ul>
                </section>

                <section className="">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="mt-5">
                    Introduction to Architecture-Driven Modernization
                    </h3>
                    <p>
                    Software architecture is, simply, the organization of a system. This organization includes all components, how they interact with each other, the environment in which they operate, and the principles used to design the software.
                    </p>
                    <p>
                      <b>
                      It is important to understand that Information Technology has been selling technical architecture transformation via various concepts: <br/>service-oriented architecture and <br/>model-driven architecture (MDA) to executives, and management has invested millions of dollars in pursuit of these architectures. 
                      </b>
                    </p>                   
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img src="assets/img/list/other/8.png" className="mb-5 mt-5" />
                  </div>
                </section>

                <section>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <img src="assets/img/list/other/6.svg" className="" style={{ position: "relative", top: "12px" }}/>
                  </div>                  
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}

      <Testimonials />

      {/* Star Partner Area
    ============================================= */}
      <div className="partner-area bg-gray shadow-less overflow-hidden text-light">
        <div className="container">
          <div className="item-box">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h2>
                  We're Trusted by <span>2500+</span> <br /> Professional
                  Customer
                </h2>
                <p>
                  Seeing rather her you not esteem men settle genius excuse.
                  Deal say over you age from. Comparison new ham melancholy son
                  themselves.
                </p>
              </div>
              <div className="col-lg-6 clients">
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}
    </div>
  );
}

