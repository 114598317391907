import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Router from '../../routes';

const Header = () => {
    const form = useRef();

    const [isVisble, setIsVisible] = useState(true);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_pyfyy4g', 'template_fz2x8qa', form.current, 'user_FIgygPWj3vQuFBn77mIao')
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    title: 'Thank you for registering!',
                    text: 'We will get back soon.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    title: 'Error!',
                    text: error?.response?.data?.error?.message ? error?.response?.data?.error?.message : 'Something went wrong!',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            });
        e.target.reset()
    };

    const onSidebar = () => {
        setIsVisible(true)
    }

    const closeSidebar = () => {
        setIsVisible(false)
    }

    return (
        <>
            {/* Start Preloader 
    ============================================= */}
            <div id="preloader">
                <div id="opensoft-preloader" className="opensoft-preloader">
                    <div className="animation-preloader">
                        <div className="spinner" />
                        <div className="txt-loading">
                            <span data-text-preloader="O" className="letters-loading">
                                O
                            </span>
                            <span data-text-preloader="P" className="letters-loading">
                                P
                            </span>
                            <span data-text-preloader="E" className="letters-loading">
                                E
                            </span>   
                            <span data-text-preloader="N" className="letters-loading">
                                N
                            </span>   
                            <span data-text-preloader="S" className="letters-loading">
                                S
                            </span>   
                            <span data-text-preloader="O" className="letters-loading">
                                O
                            </span>   
                            <span data-text-preloader="F" className="letters-loading">
                                F
                            </span>   
                            <span data-text-preloader="T" className="letters-loading">
                                T
                            </span>                           
                        </div>
                    </div>
                    <div className="loader">
                        <div className="row">
                            <div className="col-3 loader-section section-left">
                                <div className="bg" />
                            </div>
                            <div className="col-3 loader-section section-left">
                                <div className="bg" />
                            </div>
                            <div className="col-3 loader-section section-right">
                                <div className="bg" />
                            </div>
                            <div className="col-3 loader-section section-right">
                                <div className="bg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Preloader */}
            {/* Header 
    ============================================= */}
            <header id="home">
                {/* Start Navigation */}
                <nav className="navbar navbar-default attr-bg navbar-fixed white no-background bootsnav">
                    {/* Start Top Search */}
                    <div className="top-search">
                        <div className="container">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-search" /></span>
                                <input type="text" className="form-control" placeholder="Search" />
                                <span className="input-group-addon close-search"><i className="fa fa-times" /></span>
                            </div>
                        </div>
                    </div>
                    {/* End Top Search */}
                    <div className="container d-flex justify-content-between">
                       
                        {/* Start Header Navigation */}
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                <i className="fa fa-bars" />
                            </button>
                            <a className="navbar-brand" href="/">
                                <img src="assets/img/logo-light.png" className="logo logo-display" alt="Logo" />
                                <img src="assets/img/test-logo.png" className="logo logo-scrolled" alt="Logo" />
                            </a>
                        </div>
                        {/* End Header Navigation */}
                        {/* Collect the nav links, forms, and other content for toggling */}
                        <div className="collapse navbar-collapse" id="navbar-menu">
                            <ul className="nav navbar-nav navbar-center" data-in="#" data-out="#">
                                <li className="">
                                    <Link to="/">Home</Link>
                                </li>
                                
                                <li className="">
                                    <Link to="/about-us">About</Link>
                                </li>    
                                <li className="">
                                    <Link to="/services">Services</Link>
                                </li>                                                             
                                <li className="dropdown megamenu-fw remove-padding">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">Our Offerings</a>
                                    <ul className="dropdown-menu megamenu-content" role="menu">
                                        <li>
                                            <div className="row">
                                                <div className="col-menu col-lg-4 web-frame">
                                                    <h6 className="title">Web Framework</h6>
                                                    <div className="content">
                                                        <ul className="menu-col">
                                                            <li><Link to="/angular">Angular</Link></li>
                                                             <li><Link to="/react">ReactJS</Link></li>    
                                                            <li><Link to="/vue">VUE JS</Link></li>                                                          
                                                            <li><Link to="/full-stack-development">Full Stack Development</Link></li>
                                                            {/* <li><Link to="/python">Python</Link></li> */}
                                                            <li><Link to="/ruby">Ruby on Rails</Link></li>                                                          
                                                        </ul>
                                                    </div>
                                                </div>{/* end col-3 */}
                                                {/* <div className="col-menu col-lg-3 cms">
                                                    <h6 className="title">CMS</h6>
                                                    <div className="content">
                                                        <ul className="menu-col">
                                                            <li><Link to="/wordpress">Wordpress</Link></li>
                                                                <li><Link to="/drupal">Drupal</Link></li>
                                                            <li><Link to="square-space">Square Space</Link></li>
                                                          
                                                        </ul>
                                                    </div>
                                                </div> */}
                                                <div className="col-menu col-lg-4 mobile-app">
                                                    <h6 className="title">Andriod/iOS Services</h6>
                                                    <div className="content">
                                                        <ul className="menu-col">                                                                                                                                                                                
                                                            <li><Link to="react-native">React Native</Link></li>
                                                            <li><Link to="flutter">Flutter</Link></li>
                                                            {/* <li><Link to="android-ios">Android/iOS</Link></li> */}
                                                            <li><Link to="ionic">Ionic</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>{/* end col-3 */}
                                                <div className="col-menu col-lg-4 cloud">
                                                    <h6 className="title">Cloud Computing</h6>
                                                    <div className="content">
                                                        <ul className="menu-col">
                                                            <li><Link to="cloudops">Cloud Ops</Link></li>
                                                            <li><Link to="devops">DevOps</Link></li>
                                                            <li><Link to="docker">Docker</Link></li>
                                                            <li><Link to="containers">Containers</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>{/* end col-3 */}
                                            </div>{/* end row */}
                                        </li>
                                    </ul>
                                </li>  

                                <li className="dropdown dropdown-right">
                                    <a href="#home" className="dropdown-toggle smooth-menu active" data-toggle="dropdown" >Specialization</a>
                                    <ul className="dropdown-menu">
                                        <li><Link to="hire-skilled-engineers">Hire Skilled Engineers</Link></li>
                                        <li><Link to="product-development">Product Development</Link></li>
                                        <li><Link to="it-architecture">IT Architecture</Link></li>  
                                        <li><Link to="/blog">Blog</Link></li>                                        
                                    </ul>
                                </li> 

                                <li className="">
                                    <Link to="careers">Careers</Link>
                                </li>                                                                             
                                <li className="">
                                    <Link to="/contact-us">Reach us</Link>
                                </li>
                            </ul>
                        </div>{/* /.navbar-collapse */}
                    </div>
                    {/* Start Side Menu */}
                    <div className="side">
                        <a href="#" className="close-side"><i className="icon_close" /></a>
                        <div className="widget">
                            <img src="assets/img/test-logo.png" alt="Logo" />
                            <p>
                                Opensoft Group is always help you on unlock better team collaboration, communication, improved innovation and increased financial revenue and performance by great place to work.
                            </p>
                        </div>
                        <div className="widget address">
                            <div>
                                <ul>
                                    <li>
                                        <div className="content">
                                            <p>Address</p>
                                            <strong>
                                                405 State Highway 121  <br />
                                                Suite A250 <br />
                                                Lewisville TX 75067.
                                            </strong>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content">
                                            <p>Email</p>
                                            <strong>hr@opensoftgroup.com</strong>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content">
                                            <p>Contact</p>
                                            <strong>+980-222-1233</strong>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget newsletter">
                            <h4 className="title">Get Subscribed!</h4>
                            <form action="#">
                                <div className="input-group stylish-input-group">
                                    <input type="email" placeholder="Enter your e-mail" className="form-control" name="email" />
                                    <span className="input-group-addon">
                                        <button type="submit">
                                            <i className="arrow_right" />
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="widget social">
                            <ul className="link">
                                <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                                <li><a href="#"><i className="fab fa-twitter" /></a></li>
                                <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                            </ul>
                        </div>
                    </div>
                    {/* End Side Menu */}
                </nav>
                {/* End Navigation */}
            </header>
            {/* End Header */}

            <Router />

        </>
    );
};

export default Header;